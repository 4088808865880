import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const SecondPage = () => (
  <Layout>
    <SEO
      title="Architect in Colorado Testimonials"
      description="Read what our clients have to say about us. See for yourself how JKA Design can help you achieve your dream house."
    />
    <div class="page-headline">
      <div class="container">
        <div class="section-heading text-center">
          <h6 class="font-weight-bold text-uppercase text-white-50 flair">
            Reviews of JKA Design from Real People
          </h6>
          <h1>
            <strong>Testimonials</strong>
          </h1>
        </div>
      </div>
    </div>
    <div class="page-content">
      <div class="container">
        <div class="row justify-content-between align-items-center">
          <div class="col-md-12 mb-4">
            <div className="testimonial-list">
              <p className="testimonial-p">
                “Jeff is a talented professional with an Ivy League degree that
                supports his uncanny designs. Jeff listens and translates the
                clients’ dreams into reality. I have seen many JKA houses and am
                always impressed with how they fit the landscape and actually
                enhance the setting. I highly recommend JKA for both new
                construction and also for any redesigns that will increase home
                values with Jeff’s insightful designs.”
              </p>
            </div>
            <div className="testimonial-list">
              <p className="testimonial-p">
                “I have known Jeff Abrams for many years. It is my distinct
                pleasure to recommend his architectural and design services to
                anyone seeking excellence in architectural services.
              </p>
              <p className="testimonial-p">
                My first meeting with Jeff took place after I saw several homes
                Jeff had built. I made an appointment to discuss hiring Jeff to
                build a home for me. We met in his home, which he had designed
                and built. I was blown away by this home, Jeff was about to list
                it, and I bought that home from Jeff.
              </p>
              <p className="testimonial-p">
                18 years later we needed more room, so I asked Jeff to design an
                addition to our home. He did. We went forward with that
                addition. It was beautifully done and integrated so well that
                only our oldest friends knew what part of our home was new.
              </p>
              <p className="testimonial-p">
                10 years after that we asked Jeff to design a second addition.
                He did, and it, too, was beautifully conceived. We loved the
                plans but deferred that project because the 2007 Great Recession
                occurred.
              </p>
              <p className="testimonial-p">
                When we decided a few years later to sell this home that we had
                owned for nearly 38 years, many prospective buyers commented
                that they loved the fact that we had used the original architect
                for our addition. Several prospective buyers (and the ultimate
                buyers) loved the plans and the scale model Jeff did for the
                unbuilt second addition. Many commented how beautifully
                integrated it was, and how seamlessly it would expand this
                living space.
              </p>
              <p className="testimonial-p">
                We loved our Jeff Abrams home. We raised our two children there
                and lived there almost 38 years. That home had both amazing
                live-ability and the uniqueness of a one-of-a kind design. That
                said, the aspect of Jeff’s architectural brilliance that we
                consider most impressive was Jeff’s ability to grow our home as
                our needs changed, and to do it so well it was seamless.”
              </p>
              <h5 className="testimonial-user">
                –William and Tiana G., Homeowners
              </h5>
            </div>
            <div className="testimonial-list">
              <p className="testimonial-p">
                “I contacted Jeff after viewing his website. I liked his
                presentation on the site. It was more than photos of past
                projects; he included the necessary steps that needed to be
                taken to accomplish our goal. Jeff and Dillon have been terrific
                professionals to guide us through this process. Our project is a
                complicated one – with many twists and turns depending on Denver
                zoning guidelines.
              </p>
              <p className="testimonial-p">
                I feel as if Jeff and his team are concerned friends and
                although this is a relatively small project – Jeff is there for
                us. I obviously would recommend this company highly.”
              </p>
              <h5 className="testimonial-user">–Maureen P., Client</h5>
            </div>
            <div className="testimonial-list">
              <p className="testimonial-p">
                “Jeff Abrams of JKA Design was wonderful to work with. He
                patiently guided us through exploration of many different design
                options for remodeling our home to add an additional bedroom,
                new bathroom and expansion of our master bedroom suite. He
                helped us align our objectives for the home addition with our
                available budget. His experience and vision were invaluable.
                Additionally, Jeff is just great person. We enjoyed learning
                from him and just spending time with him.”
              </p>
              <h5 className="testimonial-user">
                –Bob and Betsy M., Homeowners
              </h5>
            </div>
            <div className="testimonial-list">
              <p className="testimonial-p">
                “In my lifetime of hiring various professionals to help with
                jobs regarding our home, Jeff ranks at the top. His extensive
                professional background and remodeling experience mean that he
                can do so many different types of designs, both small and large.
                His designs are works of art, but even better they work and are
                functional. He also can take homeowners ideas and turn them into
                reality.”
              </p>
              <p className="testimonial-p">
                He was the architect for our home about 40 years ago. EVERYTHING
                he said and advised us back then has held true up to today.
                That’s why we were so glad that Jeff had not retired when we
                finally decided to remodel our kitchen this year.
              </p>
              <p className="testimonial-p">
                He is a hard worker, gets plans done on time, and is an
                easy-going guy. Both my wife and myself truly enjoy working with
                him.”
              </p>
              <h5 className="testimonial-user">–Robert K., Homeowner</h5>
            </div>
            <div className="testimonial-list">
              <p className="testimonial-p">
                “Over the years I have used JKA Design on commercial properties,
                acting as the property manager for the respective building
                owners. JKA design has always done a professional job in a
                timely fashion. I have also used JKA Design for drawings and
                ideas for upgrades at our home with regards to our master bath.”
              </p>
            </div>
            <div className="testimonial-list">
              <p className="testimonial-p">
                “We live in a home designed by Jeff Abrams and for more than 20
                years have been delighted by his work. Our home is both
                beautiful and functional as a result of Jeff’s thoughtful
                creativity. Jeff has also advised us over the years on potential
                remodel and improvement projects. We value and appreciate his
                insights and suggestions. We highly recommend Jeff Abrams as a
                home design and remodel professional.”
              </p>
              <h5 className="testimonial-user">–Gordon R., Homeowner</h5>
            </div>
            <div className="testimonial-list">
              <p className="testimonial-p">
                “Jeff and his intern Sean provided the architectural drawings
                for a kitchen and bath remodel that requires moving two exterior
                walls in our home. Sean visited the site on multiple occasions
                to take pictures and measurements so that he & Jeff could
                accurately design plans and elevations. Jeff and Sean worked
                with my designer and me to insure that we all shared the same
                vision for the project. Jeff is always cost-conscious and
                follows through with timely email communications. Additionally,
                Jeff & Sean wisely suggested that we update our original 1988
                house plans to reflect the addition for future reference.
              </p>
              <p className="testimonial-p">
                I am also working with Jeff on plans to update the remaining
                exterior of the house. As of August 14, 2015, we are still in
                the pre-construction phase, so pictures are not available. I
                highly recommend JKA Design for anyone who desires honesty,
                professionalism and a delightful building partner!”
              </p>
            </div>
            <div className="testimonial-list">
              <p className="testimonial-p">
                “Jeff is the perfect architect. My husband and I can say this as
                we have used his services several times over the past 37 years.
                We first consulted him to design our mountain home in 1977. He
                met with us, discussed our lifestyle, matched the home to our
                environmental surroundings, and suggested materials that would
                be beautiful and last for many years.
              </p>
              <p className="testimonial-p">
                We love our home; we love to come home to it; it not only looks
                good, but it functions well. Jeff made many suggestions that
                have added to the value and livability of our home. For example,
                offsetting of the master bedroom so southern light could enter
                it, modifying the plan to maximize passive solar (this was
                before you heard much about passive solar), adding an additional
                level for very little $, siting the house for the best view
                while maintaining the best natural heating, cooling, and light
                features.
              </p>
              <p className="testimonial-p">
                We have never needed air conditioning in the summer due to his
                excellent positioning of windows. The reverse is true in the
                winter, where we seldom need to heat at night–only on very cold
                nights when there has been little solar gain in the day, or on
                cold days when we do not get any solar gain. During sunny,
                winter days when the outside temperature is 0 degrees, we often
                have to open windows because the solar gain is so efficient. We
                can honestly say that the money we have saved on fuel costs
                through the years more than offsets Jeff’s design feels.
              </p>
              <p className="testimonial-p">
                Our house is beautiful as well. It blends into our natural
                environment and visitors often comment on how wonderful it
                looks. Even better, is the fact that it “lives” well. We’ve seen
                too many gorgeous houses that don’t function well for the people
                who live there. If we come home tired and frustrated, our home
                is welcoming and “nurturing.”
              </p>
              <p className="testimonial-p">
                Jeff is also respectful during the planning phase. He is deeply
                interested in your opinion and what you want. He’s easy to work
                with and even if he disagrees with one of your ideas, he’ll
                politely say why, but defer to your desires.
              </p>
              <p className="testimonial-p">
                After 37 wonderful, happy years in our “Jeff” home, we’re ready
                for a kitchen remodel. Of course, we would consider only Jeff
                for the job. Jeff has that terrific combination of abilities to
                design a home to be beautiful, intelligent, and practical.”
              </p>
              <h5 className="testimonial-user">–Cinda K., Homeowner</h5>
            </div>
            <div className="testimonial-list">
              <p className="testimonial-p">
                “My wife and I asked Jeff Abrams to present ideas for the
                upgrade and improvements to the exterior of our 90 year old
                house. We met with Jeff and his assistant twice to discuss
                alternative possibilities, including adding architectural
                details to the exterior and redesigning a patio roof. Jeff and
                his assistant created several elevation drawings of the front of
                the house and the patio roof design, showing alternate detail
                additions. The CADD generated idea sketch drawings have been
                quite helpful in making our decisions with regard to choosing
                the detail additions that we will proceed with.”
              </p>
              <h5 className="testimonial-user">–Devin R., Homeowner</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default SecondPage
